import React, { useEffect, useRef } from "react";
import RotatingWords from "./rotatingWords";

const BannerHeader = () => {
  const headerRef = useRef(null);
  const words = ["Empower", "Educate", "Elevate"];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      headerRef.current.style.transform = `translateY(${
        scrollPosition * 0.5
      }px)`;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <h1 className="home__hero-heading-bg" ref={headerRef}>
        <RotatingWords words={words} />
      </h1>
    </>
  );
};

export default BannerHeader;
