import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";

import BannerHeader from "../components/bannerHeader";

import IMG01 from ".././assets/images/zemura_09.png";
import IMG02 from ".././assets/images/zemura_bg.png";
import IMG03 from ".././assets/images/zemura-12.png";

import { ReactComponent as Quote } from ".././assets/svg/icons8-quote.svg";

const About = () => {
  return (
    <>
      <Header />
      <section className="about__hero-section">
        <div className="about__overlay"></div>
        <div className="container">
          <div className="about__hero-text">
            <BannerHeader text="About Us" />
          </div>
        </div>
      </section>
      <section className="overlay">
        <div className="container">
          <div className="about__zemura-row">
            <div className="about__zemura-col-left">
              <img src={IMG01} alt="IMG" />
              <div className="about__zemura-overlay"></div>
            </div>
            <div className="about__zemura-col-right">
              <div className="about__zemura-text-box">
                <p className="about__zemura-text-small">
                  Introducing Our Visionary Leader
                </p>
                <h2 className="about__zemura-text-heading">
                  Meet Jordan Zemura
                </h2>
                <p className="about__zemura-text-para">
                  Jordan Zemura is a talented Zimbabwean footballer who plays
                  left-back for Serie A club Udinese Calcio and the Zimbabwe
                  Warriors. Born to Zimbabwean parents in Lambeth England,
                  Zemura's football journey began in the youth ranks of Queens
                  Park Rangers before he moved to Charlton Athletic and
                  eventually joined Bournemouth's academy in 2019.
                </p>
                <p className="about__zemura-text-para">
                  Through his parents, Jordan has always had a connection with
                  Zimbabwe and in 2020, Jordan proudly made his debut for the
                  Zimbabwe National team. Embracing the opportunity to connect
                  with his roots, Jordan founded The Jordan Zemura Foundation,
                  dedicated to transforming communities through sports and
                  mentorship.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about__mission-section">
        <div className="container">
          <div className="about__mission-para-box">
            <p className="about__zemura-text-small">What we do</p>
            <h2 className="about__zemura-text-heading">
              Impacting Lives, One Step at a Time
            </h2>

            <p className="about__mission-para-text">
              The Jordan Zemura Foundation aims to support children and steer them
              away from negative influences such as drugs by engaging them in
              positive, structured activities that provide physical exercise,
              mentorship, and life skills education.
            </p>
          </div>
          <div className="about__img-full">
            <img src={IMG02} alt="IMG" />
            <div className="about__zemura-overlay"></div>
          </div>
        </div>
      </section>

      <section className="about__vision-section">
        <div className="container">
          <div className="about__vision-row">
            <div className="about__vision-col-left">
              <div className="about__vision-image">
                <img src={IMG03} alt="IMG" />
                <div className="about__zemura-overlay"></div>
              </div>
            </div>
            <div className="about__vision-col-right">
              <div className="about__vision-item">
                <h3 className="about__vision-title">Mission</h3>
                <p className="about__vision-text">
                  At The Jordan Zemura Foundation we are dedicated to
                  transforming communities through the power of sport and
                  mentorship. Our mission is to empower individuals, inspire
                  positive change, and foster a sense of unity and purpose. By
                  leveraging the universal language of sport and the profound
                  impact of mentorship, we strive to create lasting
                  opportunities and brighter futures for those we serve.
                </p>
              </div>
              <div className="about__vision-item">
                <h3 className="about__vision-title">Vision</h3>
                <p className="about__vision-text">
                  We envision a world where every community thrives, where young
                  people are supported to reach their full potential, and where
                  the values of teamwork, perseverance, and mutual respect are
                  cultivated and celebrated. Through our programs, we aim to
                  break down barriers, build bridges, and inspire the next
                  generation of leaders.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default About;
