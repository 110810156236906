import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import BannerHeader from "../components/bannerHeader";

import IMG01 from ".././assets/images/zemura_09.png";

import { ReactComponent as Quote } from ".././assets/svg/icons8-quote.svg";

import { ReactComponent as Location } from "../assets/svg/icons8-location-1.svg";
import { ReactComponent as Phone } from "../assets/svg/icons8-phone-1.svg";
import { ReactComponent as Mail } from "../assets/svg/icons8-mail-1.svg";

const Contact = () => {
  return (
    <>
      <Header />
      <section className="about__hero-section">
        <div className="about__overlay"></div>
        <div className="container">
          <div className="about__hero-text">
            <BannerHeader text="Contact Us" />
          </div>
        </div>
      </section>
      <section className="overlay">
        <div className="container">
          <div class="contact__content-grid">
            <div class="contact__content-bg-white">
              <div class="contact__content-featured-box">
                <div class="contact__content-featured-box-icon ">
                  <Location />
                </div>
                <h3>Location</h3>
                <p>148 Baines Avenue, Avenues, Harare</p>
                <p>51 Upper Chalvington Place Brighton BN2 5GY, United Kingdom</p>
              </div>
            </div>

            <div class="contact__content-bg-white">
              <div class="contact__content-featured-box">
                <div class="contact__content-featured-box-icon ">
                  <Phone />
                </div>
                <h3>Phone</h3>
                <p>(+263) 777027620</p>
                <p>(+44) 7366309942</p>
              </div>
            </div>

            <div class="contact__content-bg-white">
              <div class="contact__content-featured-box">
                <div class="contact__content-featured-box-icon ">
                  <Mail />
                </div>
                <h3>Email</h3>
                <p>info@jordanzemurafoundation.org</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact__form-section">
        <div className="container">
          <div className="contact__form-wrapper">
            <div className="contact__form-box">
              <div className="contact__form-heading">
                <p className="contact__text-small">Get in Touch</p>
                <h2 className="contact__text-heading">Contact Us</h2>
              </div>

              <div className="contact__form-container">
                <div className="form__group">
                  <label className="form__label">Full name</label>
                  <input className="form__input" type="text" />
                </div>
                <div className="form__group">
                  <label className="form__label">Email</label>
                  <input className="form__input" type="text" />
                </div>
                <div className="form__group">
                  <label className="form__label">Subject</label>
                  <input className="form__input" type="text" />
                </div>
                <div className="form__group">
                  <label className="form__label">Message</label>
                  <input className="form__input" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Contact;
