import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";

import BannerHeader from "../components/bannerHeader";

import EVENT05 from "../assets/images/event_05.webp";
import EVENT06 from "../assets/images/event_06.webp";
import EVENT07 from "../assets/images/event_07.webp";

const About = () => {
  return (
    <>
      <Header />
      <section className="about__hero-section">
        <div className="about__overlay"></div>
        <div className="container">
          <div className="about__hero-text">
            <BannerHeader text="What we do" />
          </div>
        </div>
      </section>
      <section className="overlay">
        <div className="container">
          <p className="about__zemura-text-small">Explore Our Initiatives</p>
          <h2 className="about__zemura-text-heading">
            Projects That Make a Difference
          </h2>

          <p className="about__zemura-text-para">
            The Jordan Zemura Foundation supports children and steers them away
            from negative influences such as drugs by engaging them in positive,
            structured activities that provide physical exercise, mentorship,
            and life skills education.
          </p>

          <div className="projects__list u-margin-top-medium">
            <div className="projects__item">
              <h3 className="projects__heading">Sports Programs</h3>
              <div className="projects__info-container">
                <h4 className="projects__sub-title">
                  Football tournaments and events
                </h4>
                <p className="projects__desc">
                  Description:{" "}
                  <span>
                    {" "}
                    Host regular sports tournaments and events that involve the
                    community and offer rewards for participation and good
                    sportsmanship
                  </span>
                </p>
                <p className="projects__desc">
                  Objective:{" "}
                  <span>
                    {" "}
                    Create a sense of excitement and achievement, providing
                    positive alternatives to substance abuse and negative
                    influences
                  </span>
                </p>
              </div>
              <div className="projects__info-container">
                <h4 className="projects__sub-title">
                  Sports and Life Skills Workshops
                </h4>
                <p className="projects__desc">
                  Description:{" "}
                  <span>
                    {" "}
                    Integrate life skills workshops into sports training
                    sessions, covering topics like goal-setting, communication,
                    and substance abuse.
                  </span>
                </p>
                <p className="projects__desc">
                  Objective:{" "}
                  <span>
                    {" "}
                    Teach kids valuable life skills that help them make better
                    decisions and avoid substance abuse
                  </span>
                </p>
              </div>
              <div className="projects__info-container">
                <h4 className="projects__sub-title">Sports Camps</h4>
                <p className="projects__desc">
                  Description:{" "}
                  <span>
                    {" "}
                    Organize seasonal sports camps focusing on various sports
                    skills, teamwork, and personal development
                  </span>
                </p>
                <p className="projects__desc">
                  Objective:{" "}
                  <span>
                    {" "}
                    Provide intensive, focused periods of engagement,
                    particularly during school holidays
                  </span>
                </p>
              </div>
            </div>
            <div className="projects__item">
              <h3 className="projects__heading">Mentorship Initiatives</h3>
              <div className="projects__info-container">
                <h4 className="projects__sub-title">
                  Improving Access to Education
                </h4>
                <p className="projects__desc">
                  Scholarships and Financial Aid:{" "}
                  <span>
                    {" "}
                    Provide scholarships, bursaries, and other financial support
                    to children from low-income families to cover school fees,
                    uniforms, books, and other necessities
                  </span>
                </p>
              </div>
              <div className="projects__info-container">
                <h4 className="projects__sub-title">
                  Mentorship Through Sports
                </h4>
                <p className="projects__desc">
                  Description:{" "}
                  <span>
                    {" "}
                    Pair kids with older athletes or coaches who can serve as
                    mentors, providing guidance and support both on and off the
                    field
                  </span>
                </p>
                <p className="projects__desc">
                  Objective:{" "}
                  <span>
                    {" "}
                    Offer positive role models and build supportive
                    relationships that discourage substance abuse
                  </span>
                </p>
              </div>
              <div className="projects__info-container">
                <h4 className="projects__sub-title">
                  Sports Volunteering Opportunities
                </h4>
                <p className="projects__desc">
                  Description:{" "}
                  <span>
                    {" "}
                    Encourage kids to volunteer in sports events or as assistant
                    coaches
                  </span>
                </p>
                <p className="projects__desc">
                  Objective:{" "}
                  <span>
                    {" "}
                    Instill a sense of responsibility and community involvement,
                    giving them a purpose and steering them away from substance
                    abuse
                  </span>
                </p>
              </div>
            </div>
            <div className="projects__item">
              <h3 className="projects__heading">Sports Programs</h3>
              <div className="projects__info-container">
                <h4 className="projects__sub-title">Safe water for Everyone</h4>
                <p className="projects__desc">
                  Description:{" "}
                  <span> Provide reliable access to groundwater</span>
                </p>
                <p className="projects__desc">
                  Programs:{" "}
                  <span>
                    {" "}
                    Drilling new boreholes and rehabilitating existing ones to
                    ensure communities have year-round access to clean water
                  </span>
                </p>
              </div>
              <div className="projects__info-container">
                <h4 className="projects__sub-title">Youth Engagement</h4>
                <p className="projects__desc">
                  Vocational Training:{" "}
                  <span>
                    {" "}
                    Provide vocational training and career counseling for youth
                    to enhance their employability
                  </span>
                </p>
                <p className="projects__desc">
                  Youth Programs:{" "}
                  <span>
                    {" "}
                    Develop youth programs that promote leadership, sports, and
                    cultural activities
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default About;
