import React, { useEffect, useRef } from 'react';
import TypewriterText from "./typeWriterText";

const BannerHeader = ({ text }) => {
  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      headerRef.current.style.transform = `translateY(${
        scrollPosition * 0.3
      }px)`;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <h1 className="about__hero-heading-bg" ref={headerRef}>
        <TypewriterText text={text} />
      </h1>
    </>
  );
};

export default BannerHeader;
