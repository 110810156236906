import React from "react";
import { BrowserRouter as Router, Routes, Link } from "react-router-dom";

import { ReactComponent as Facebook } from ".././assets/svg/icons8-facebook.svg";
import { ReactComponent as Linkedin } from ".././assets/svg/linkedin.svg";
import { ReactComponent as Mail } from ".././assets/svg/mail.svg";
import { ReactComponent as Instagram } from ".././assets/svg/icons8-instagram.svg";
import { ReactComponent as Youtube } from ".././assets/svg/icons8-youtube.svg";
import { ReactComponent as Twitter } from ".././assets/svg/icons8-twitterx.svg";
import { ReactComponent as Logo } from ".././assets/images/33.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__social-container">
            <div className="footer__icon">
              <Instagram />
            </div>
            <div className="footer__icon">
              <Twitter />
            </div>
            <div className="footer__icon">
              <Facebook />
            </div>
            <div className="footer__icon">
              <Youtube />
            </div>
          </div>
          <div className="footer__links">
            <ul className="header__list">
              <li className="header__item">
                <Link exact to={`/`} className="header__link">
                  home
                </Link>
              </li>
              <li className="header__item">
                <Link to={`/about`} className="header__link">
                  about
                </Link>
              </li>
              <li className="header__item">
                <Link to={`/what-we-do`} className="header__link">
                  what we do
                </Link>
              </li>
              <li className="header__item">
                <Link to={`/contact`} className="header__link">
                  contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer__bar">
        <div className="container">
          <div className="">
            <p className="">
              Copyright © 2024 All rights reserved | developed by Kdigital
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
