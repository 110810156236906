import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./scrollToTop";

import About from "./pages/about";
import Contact from "./pages/contact";
import Compaigns from "./pages/compaigns";
import Home from "./pages/Home";
import Projects from "./pages/projects";

const Routing = () => {
  // ScrollToTop();

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/what-we-do" exact element={<Projects />} />
        <Route path="/compaigns" exact element={<Compaigns />} />
        <Route path="/contact" exact element={<Contact />} />
      </Routes>
    </Router>
  );
};
export default Routing;
