import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";


const About = () => {
  return (
    <>
      <Header />
      <section className="about__hero-section">
        <div className="about__overlay"></div>
        <div className="container">
          <div className="about__hero-text">
            <h1 className="about__hero-heading-bg">Compaigns</h1>
          </div>
        </div>
      </section>
      <section className="overlay"></section>

      <Footer/>
    </>
  );
};
export default About;
