import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Link } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Section from "../components/section";
import HeadingTitle from "../components/headingTitle";

import EVENT05 from "../assets/images/zemura-01.png";

import { ReactComponent as ArrowRight } from ".././assets/svg/arrow-right.svg";

const Home = () => {
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      const scrollPosition = window.scrollY;
      sections.forEach((section, index) => {
        if (
          scrollPosition >= index * window.innerHeight &&
          scrollPosition < (index + 1) * window.innerHeight
        ) {
          section.style.opacity = "1";
        } else {
          section.style.opacity = "0.5";
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Header />

      <section className="home__hero-section">
        <div className="home__overlay"></div>
        <div className="container">
          <div className="home__hero-text">
            <HeadingTitle />
            <p className="home__hero-text-sm">
              Transforming communities through sports and mentorship
            </p>
            {/* <div className="home__hero-col-left"></div>
            <div className="home__hero-col-right">
              <h1 className="home__hero-heading-bg">
                WE CAN HELP TO SAVE THE WORLD
              </h1>
            </div> */}
          </div>
        </div>
      </section>
      <section className="overlay">
        <div className="container">
          <div className="about__mission-para-box">
            {/* <p className="about__zemura-text-small">What we do</p> */}
            <h2 className="about__zemura-text-heading">
              The Jordan Zemura Foundation
            </h2>

            <p className="about__mission-para-text">
              The Jordan Zemura Foundation supports children and steers them
              away from negative influences such as drugs by engaging them in
              positive, structured activities that provide physical exercise,
              mentorship, and life skills education.
            </p>
          </div>
        </div>
      </section>
      <section className="home__what-section">
        <div className="container">
          <div className="about__mission-para-box">
            <h2 className="about__zemura-text-heading">What we do</h2>
          </div>

          <div className="home__list u-margin-top-medium">
            <div className="home__item">
              <div className="home__image">
                <img src={EVENT05} alt="img" />
                <div className="about__zemura-overlay"></div>
              </div>
            </div>
            <div className="home__projects-text-wrapper">
              <p className="home__projects-text-para">
                At The Jordan Zemura Foundation, we are dedicated to
                transforming communities through sports and mentorship. Our
                programs support children by engaging them in positive,
                structured activities to keep them away from negative influences
                like drugs. We host football tournaments, life skills workshops,
                and sports camps to promote physical exercise and personal
                development. Our mentorship initiatives include providing
                scholarships, pairing kids with mentors, and encouraging
                volunteering to build supportive relationships and instill a
                sense of responsibility. Additionally, we ensure reliable access
                to clean water through borehole projects and offer vocational
                training to enhance youth employability.
              </p>
              <Link to={`/what-we-do`} className="home__projects-more-btn">
                Read more <ArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="">
            <div className="about__mission-para-box">
              <h2 className="about__zemura-text-heading">Mission</h2>

              <p className="about__mission-para-text">
                At The Jordan Zemura Foundation we are dedicated to transforming
                communities through the power of sport and mentorship. Our
                mission is to empower individuals, inspire positive change, and
                foster a sense of unity and purpose. By leveraging the universal
                language of sport and the profound impact of mentorship, we
                strive to create lasting opportunities and brighter futures for
                those we serve.
              </p>
            </div>
            <div className="about__mission-para-box u-margin-top-big u-margin-bottom-big">
             
              <h2 className="about__zemura-text-heading">Vision</h2>
              <p className="about__mission-para-text">
                We envision a world where every community thrives, where young
                people are supported to reach their full potential, and where
                the values of teamwork, perseverance, and mutual respect are
                cultivated and celebrated. Through our programs, we aim to break
                down barriers, build bridges, and inspire the next generation of
                leaders.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Home;
