import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  NavLink,
  Link,
} from "react-router-dom";
import Logo from "../assets/images/logo_white.png";

import EVENT01 from "../assets/images/event_00.webp";

import { ReactComponent as Location } from "../assets/svg/icons8-location.svg";
import { ReactComponent as Phone } from "../assets/svg/icons8-mobile-phone.svg";
import { ReactComponent as Menu } from "../assets/svg/icons8-menu.svg";
import { ReactComponent as Close } from "../assets/svg/icons8-close.svg";
// import { ReactComponent as Logo } from ".././assets/images/33.svg";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Set isScrolled to true if page is scrolled more than 50px, otherwise false
      setIsScrolled(window.scrollY > 50);
    };

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header className={`header ${isScrolled ? "header__scrolled" : ""}`}>
      <div className="header__mobile">
        <div className="container">
          <div className="header__mobile-container">
            <div className="header__logo-box">
              <img src={Logo} alt="33" />
            </div>

            <div className="header__mobile-menu" onClick={toggleMenu}>
              <Menu />
            </div>

            <div
              className={
                isOpen
                  ? "header__mobile-nav header__mobile-nav-open"
                  : "header__mobile-nav"
              }
            >
              <div className="header__mobile-close" onClick={toggleMenu}>
                <Close />
              </div>
              <ul className="header__mobile-list">
                <li className="header__mobile-item">
                  <NavLink
                    exact
                    to={`/`}
                    className={({ isActive }) =>
                      isActive
                        ? "header__link header__link-active"
                        : "header__link"
                    }
                  >
                    home
                  </NavLink>
                </li>
                <li className="header__mobile-item">
                  <NavLink
                    to={`/about`}
                    className={({ isActive }) =>
                      isActive
                        ? "header__link header__link-active"
                        : "header__link"
                    }
                  >
                    about
                  </NavLink>
                </li>
                <li className="header__mobile-item">
                  <NavLink
                    to={`/what-we-do`}
                    className={({ isActive }) =>
                      isActive
                        ? "header__link header__link-active"
                        : "header__link"
                    }
                  >
                    what we do
                  </NavLink>
                </li>
                <li className="header__mobile-item">
                  <NavLink
                    to={`/contact`}
                    className={({ isActive }) =>
                      isActive
                        ? "header__link header__link-active"
                        : "header__link"
                    }
                  >
                    contact
                  </NavLink>
                </li>
              </ul>
            </div>
            {isOpen && (
              <div
                className="header__mobile-overlay"
                onClick={toggleMenu}
              ></div>
            )}
          </div>
        </div>
      </div>

      <div className="header__website">
        <div className="container">
          <div
            className={`header__top-header ${
              isScrolled ? "header__top-header-scrolled" : ""
            }`}
          ></div>
          <div className="header__bottom">
            <div className="header__bottom-container">
              <Link to={`/`} className="header__logo-box">
                <img src={Logo} alt="33" />
              </Link>
              <ul className="header__list">
                <li className="header__item">
                  <NavLink
                    exact
                    to={`/`}
                    className={({ isActive }) =>
                      isActive
                        ? "header__link header__link-active"
                        : "header__link"
                    }
                  >
                    home
                  </NavLink>
                </li>
                <li className="header__item">
                  <NavLink
                    to={`/about`}
                    className={({ isActive }) =>
                      isActive
                        ? "header__link header__link-active"
                        : "header__link"
                    }
                  >
                    about
                  </NavLink>
                </li>
                <li className="header__item">
                  <NavLink
                    to={`/what-we-do`}
                    className={({ isActive }) =>
                      isActive
                        ? "header__link header__link-active"
                        : "header__link"
                    }
                  >
                    what we do
                  </NavLink>
                </li>
                <li className="header__item">
                  <NavLink
                    to={`/contact`}
                    className={({ isActive }) =>
                      isActive
                        ? "header__link header__link-active"
                        : "header__link"
                    }
                  >
                    contact
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
